var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-image",
    staticStyle: {
      "background-image": "url('https://source.unsplash.com/1920x1280/?forest')"
    }
  }, [_c('div', {
    staticClass: "bg-black-25"
  }, [_c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "py-5 text-center"
  }, [_c('h1', {
    staticClass: "font-w700 my-2 text-white"
  }, [_vm._v("Edit Entity")]), _c('h2', {
    staticClass: "h4 font-w700 text-white-75"
  }, [_vm._v(_vm._s(_vm.entity.name))]), _c('router-link', {
    staticClass: "btn btn-hero-primary",
    attrs: {
      "to": {
        name: 'entity-view',
        params: {
          id: _vm.entity._id
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-arrow-left mr-1"
  }), _vm._v(" Back to entity ")])], 1)])])]), _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.entityError,
      expression: "entityError"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.entityError))]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('form', {
    attrs: {
      "method": "POST",
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "name",
      "type": "text",
      "name": "name",
      "placeholder": "Enter your name.."
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "company-number"
    }
  }, [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.companyNumber,
      expression: "form.companyNumber"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "company-number",
      "type": "text",
      "name": "company-number",
      "placeholder": "Enter your name.."
    },
    domProps: {
      "value": _vm.form.companyNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "companyNumber", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "company-type"
    }
  }, [_vm._v("Company Type")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.companyType,
      expression: "form.companyType"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "company-type",
      "type": "text",
      "name": "company-type",
      "placeholder": "Enter your name.."
    },
    domProps: {
      "value": _vm.form.companyType
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "companyType", $event.target.value);
      }
    }
  })])])]), _vm._m(2), _c('div', {
    staticClass: "row push"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('AddressForm', {
    model: {
      value: _vm.form.registeredAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registeredAddress", $$v);
      },
      expression: "form.registeredAddress"
    }
  })], 1)]), _vm._m(4)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-briefcase text-muted mr-1"
  }), _vm._v(" Company Details")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Information about the company/fund.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-building text-muted mr-1"
  }), _vm._v(" Registered Address")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Update the registered office of this company/fund.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-lg-8 col-xl-5 offset-lg-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Profile")])])])]);

}]

export { render, staticRenderFns }